import { ApiService } from "../../Lib";
import Utils from "../../Lib/Utils/Utils";

export default class TestService {
    state = "none";
    setState = (_newState) => {
        this.state = _newState;
    };

    queryParmas = {};
    setQueryParmas = (queryParmas) => {
        this.queryParmas = queryParmas;
    };

    updageQueryParams = async ({ key, value }) => {
        this.queryParmas[key] = { key, value };
        this.query = Utils.generateQuery(this.queryParmas, this.endpoint);
    };

    endpoint = "posts";
    query = this.endpoint;

    constructor() {
        this.apiService = new ApiService({
            baseURL: "https://jsonplaceholder.typicode.com",
            headers: { "Content-Type": "application/json" },
            storageKey: "test-storage-key",
            storageType: "session" || "local",
        });
    }

    getItems = async (url = this.query) => {
        return await this.apiService.get(url);
    };
    getItem = async (id) => {
        return await this.apiService.get(`${this.endpoint}/${id}`);
    };
    deleteItem = async (id) => {
        return await this.apiService.delete(`${this.endpoint}/${id}`);
    };
    addItem = async (item) => {
        return await this.apiService.post(this.endpoint, item);
    };
    updateItem = async (item) => {
        return await this.apiService.put(this.endpoint, item);
    };
}
