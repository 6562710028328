import { Div } from "../../Lib/NodeBuilder/NodeBuilder";

const ItemNode = ({ item, setItem }) => {
    return Div(
        {
            className: "item-card",
            // style:
            //     "background-color: #" +
            //     Math.floor(Math.random() * 16777215).toString(16) +
            //     "; color: #" +
            //     Math.floor(Math.random() * 16777215).toString(16) +
            // "; backdrop-filter : blur(10px);",
            onclick: ({ currentTarget }) => {
                // currentTarget.classList.toggle("active");
                item.test = Math.random();
                setItem && setItem(item);
                // currentTarget.setAttribute("item", JSON.stringify(item));
                // currentTarget.setAttribute("aria-expanded", currentTarget.classList.contains("active"));
            },
            id: item.id,
        },
        [
            Div({ className: "col gap-lg" }, [
                //
                Div({ className: "obj text-5xl text-center", innerText: item.i }, []),
                Div({ className: "obj", innerText: item.name }, []),
                Div({ className: "obj", innerText: item.wholeSalePrice }, []),
                Div({ className: "obj", innerText: item.morabaaId }, []),
                Div({ className: "obj", innerText: item.test }, []),
            ]),
        ]
    );
};

export default ItemNode;
