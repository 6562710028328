import React from "react";
import { Link, useLocation } from "react-router-dom";
import { _AuthedRoutes } from "../Routes/AuthedRoutes";
const ExamplesBottomBar = () => {
    const location = useLocation();
    return location.pathname.startsWith("/examples/") ? (
        <div className="col-center" style={{ marginTop: "auto", marginBottom: "var(--md)" }}>
            <div className="row-center gap-lg justify-center item-center m-auto overflow-auto" style={{ width: "100vw" }}>
                {_AuthedRoutes
                    .slice(1)
                    .filter((o) => o.path.startsWith("/examples/"))
                    .map(({ path, title }) => (
                        <Link to={path} key={path}>
                            <p className="text-prim font-bold bg-crow px-md py-sm rounded-xl text-center" style={{ minWidth: 100 }}>
                                {title}
                            </p>
                        </Link>
                    ))}
            </div>
        </div>
    ) : (
        <></>
    );
};

export default ExamplesBottomBar;
