import React from "react";
import { FormBuilder, JsonToView, Spliter } from "../Lib";
import FormSecripts from "../Lib/Form/FormScripts";
import Utils from "../Lib/Utils/Utils";
import ServicesCreaterUtils from "./ServicesCreaterUtils";

const ServicesCreater = () => {
    const [, render] = React.useState();
    const onFieldsChange = (field) => {
        Utils.hasValue(field.value) && (fieldsValues[field.id] = field.value);
        setTimeout(() => render(Math.random()));
    };
    const onSubmit = (e) => {
        e.preventDefault();
        console.log("submit");
    };

    return (
        <form onSubmit={onSubmit} className="flex-grow p-2xl">
            <div>
                <h1 className="text-center">Create</h1>
                <FormBuilder fields={fields} onChange={onFieldsChange} />
            </div>
            <Spliter storageKey={"services-creater"} initialRatios={[0.6, 0.4]} />
            <div>
                <h1 className="text-center p-md">Result</h1>
                {fieldsValues && <JsonToView json={fieldsValues} />}
            </div>
        </form>
    );
};

export default ServicesCreater;

const fields = [
    {
        className: "row-center rounded-md gap-md bg-throne py-xs px-md self-start items-center",
        children: [
            {
                id: "name",
                type: "text",
                title: "ServiceName",
                value: "",
                storageKey: "sc-name",
                containerClass: "query-input",
            },
            {
                id: "type",
                type: "options",
                title: "ServiceType",
                storageKey: "sc-type",
                containerClass: "query-input",
                value: ServicesCreaterUtils.Types[0].id,
                options: ServicesCreaterUtils.Types,
            },
        ],
    },
];

const fieldsValues = FormSecripts.extractValues(fields);
