import React from "react";
import Button from "../Components/Button";
import { ApiService, Spliter, JsonToView } from "../Lib";
import Toast from "../Lib/Utils/Toast";

const ApiServiceExample = () => {
    const [data, setData] = React.useState({});
    const apiService = React.useMemo(() => {
        return new ApiService({
            baseURL: "https://jsonplaceholder.typicode.com",
            headers: { "Content-Type": "application/json" },
            storageKey: "test-storage-key",
            storageType: "session" || "local",
        });
    }, []);

    const getAll = async () => {
        const response = await apiService.get("posts");
        setData(response);
    };
    const getOne = async () => {
        const response = await apiService.get("posts/1");
        setData(response);
    };

    const post = async () => {
        setTimeout(() => {
            Toast.success({ title: "تم", content: "تمت اضافة المادة بنجاح" });
        }, 1000);
        const response = await apiService.post("posts", { title: "test", body: "test", userId: 1 });
        setData(response);
    };

    return (
        <>
            <div className="px-md">
                <p className="text-center text-base text-orange">src / Examples / ApiServiceExample.js</p>
                <p className="text-center text-crow text-2xl">Api Service Example </p>
                {/* <JsonToView className="grid bg-dolphin rounded-md" json={apiService} /> */}
                <div className="row-center gap-md p-md bg-king rounded-md">
                    <Button title="Get All" onClick={getAll} />
                    <Button title="Get One" onClick={getOne} />
                    <Button title="Post" onClick={post} />
                </div>
            </div>
            <Spliter vertical storageKey="api-service-example" initialRatios={[0.1, 0.9]} />

            <div className="p-md m-md overflow-auto scroller bg-throne rounded-xl">
                {data && <JsonToView className={data.length > 1 ? "grid" : "json-builder"} json={data} />}
            </div>
        </>
    );
};

export default ApiServiceExample;
