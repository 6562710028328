import React from "react";
import JsonToView from "../Lib/NodeBuilder/JsonToView";
import Spliter from "../Lib/NodeBuilder/Spliter";
import { FormBuilder } from "../Lib/ServiceProvider";

const FormExample = () => {
    const [formValues, setFormValues] = React.useState({});

    const onFeildsInit = (fieldsValues) => {
        setTimeout(() => {
            setFormValues(Object.entries(fieldsValues).reduce((prev, [id, value]) => ({ ...prev, [id]: value.value }), {}));
        }, 0);
    };

    const onFeildsChanged = ({ id, value }) => {
        setFormValues((prev) => ({ ...prev, [id]: value }));
    };

    return (
        <>
            <div className="bg-lord p-md rounded-lg m-md" style={{ marginBottom: 0 }}>
                <p className="text-center text-base text-orange">src / Examples / FormExample.js</p>
                <p className="text-center text-crow text-2xl">Form Example </p>
                <FormBuilder fields={formFields} onChange={onFeildsChanged} init={onFeildsInit} className="form-container gap-xl row wrap" />
            </div>

            {/* Result */}
            <Spliter vertical storageKey="form-example" initialRatios={[0.1, 0.9]} />
            <div className="bg-throne p-md rounded-lg overflow-hidden m-md" style={{ marginTop: 0 }}>
                <div>
                    <p className="text-center p-md text-lg">Result</p>
                    <JsonToView json={formValues} />
                </div>
                <Spliter storageKey="form-example-two" initialRatios={[0.5, 0.5]} />
                <div className="overflow-auto ">
                    <p className="text-center p-md text-lg">Form Fields</p>
                    <JsonToView json={formFields} />
                </div>
                {/* Result*/}
            </div>
        </>
    );
};

export default FormExample;
const formFields = [
    {
        className: "row-center rounded-md gap-md bg-throne py-xs px-md self-start items-cen",
        children: [
            {
                id: "name",
                type: "search",
                title: "Name",
                value: "",
                storageKey: "items-query",
                containerClass: "query-input",
            },
            {
                id: "age",
                type: "popupSelector",
                title: "Age",
                value: "big",
                storageKey: "q-age",
                options: [
                    { id: "big", title: "اكبر" },
                    { id: "small", title: "اصغر" },
                ],
            },
        ],
    },
];

const formFields_Two = [
    {
        className: "row-center rounded-md gap-md bg-throne py-xs px-md self-start items-cen",
        children: [
            {
                id: "name",
                type: "search",
                title: "Name",
                value: "",
                storageKey: "items-query",
                containerClass: "query-input",
            },
            {
                id: "age",
                type: "popupSelector",
                title: "Age",
                value: 1,
                storageKey: "q-age",
                options: [
                    { id: "big", title: "اكبر" },
                    { id: "small", title: "اصغر" },
                ],
            },
        ],
    },
    {
        className: "row-center rounded-md gap-md bg-throne py-xs px-md self-start items-center",
        children: [
            {
                id: "currencyId",
                type: "options",
                title: "العملة",
                value: 1,
                storageKey: "q-currencyId",
                options: [
                    { id: 1, title: "دينار" },
                    { id: 2, title: "دولار" },
                ],
            },
            {
                id: "sort",
                type: "selector",
                title: "Selector",
                value: 1,
                storageKey: "q-currencyId",
                options: [
                    { id: 1, title: "التاريخ" },
                    { id: 2, title: "السعر" },
                ],
            },
            {
                id: "deleted",
                type: "toggle",
                title: "المحذوقة",
                value: false,
                storageKey: "is-query",
            },
            {
                id: "from",
                type: "date",
                title: "التاريخ من",
                storageKey: "q-from",
            },
        ],
    },
];
