import React from "react";
import { Route, Routes } from "react-router-dom";
import { Dashboard, KitTest } from "../Pages";
import PagenationViewExample from "../Examples/PagenatedServiceExample/PagenationViewExample";
import FormExample from "../Examples/FormExample";
import SpliterTest from "../Examples/SpliterTest";
import RefresherExample from "../Examples/RefresherExample";
import ApiServiceExample from "../Examples/ApiServiceExample";
import ServiceStateBuilder from "../Examples/ServiceStateBuilderExample";
import TestServiceView from "../Examples/ServiceExample/TestServiceView";
import ExamplesBottomBar from "../Examples/ExamplesBottomBar";
import ServicesCreater from "../ServiceCreater/ServicesCreater";
import PagenationNodeViewExample from "../Examples/PagenatedServiceExample/PagenationNodeViewExample";
import RecyclerTest from "../Examples/RecyclerTest";

export const _AuthedRoutes = [
    // { path: "/", element: <ServicesCreater />, title: "ServicesCreater" },
    { path: "/", element: <Dashboard />, title: "Dashboard" },
    { path: "/examples/kittest", element: <KitTest />, title: "KitTest" },
    { path: "/examples/apiservice", element: <ApiServiceExample />, title: "Api Service" },
    { path: "/examples/service", element: <TestServiceView />, title: "Service" },
    { path: "/examples/form", element: <FormExample />, title: "Form" },
    { path: "/examples/service-state", element: <ServiceStateBuilder />, title: "Service State" },
    { path: "/examples/pagenation", element: <PagenationViewExample />, title: "Pagenation Service" },
    { path: "/examples/pagenation/node", element: <PagenationNodeViewExample />, title: "Pagenation Node Service" },
    { path: "/examples/spliter", element: <SpliterTest />, title: "Spliter " },
    { path: "/examples/refresher", element: <RefresherExample />, title: "Refresher" },
    { path: "/examples/recyclertest", element: <RecyclerTest />, title: "Recycler" },
];

const AuthedRoutes = () => {
    return (
        <>
            <Routes>
                {_AuthedRoutes.map((route) => (
                    <Route key={route.path} path={route.path} element={route.element} />
                ))}
            </Routes>
            <ExamplesBottomBar />
        </>
    );
};

export default AuthedRoutes;
