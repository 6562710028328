const Language = {
    Name: "الاسم",
    Password: "كلمة المرور",
    PhoneNumber: "رقم الهاتف",
    Address: "العنوان",
    Phone: "رقم الهاتف",
    Email: "البريد الالكتروني",
    Yes: "نعم",
    No: "لا",
    Ok: "حسنا",
    Cancel: "الغاء",
    Delete: "حذف",
    Edit: "تعديل",
    Add: "اضافة",
    Save: "حفظ",
    Search: "بحث",
    Login: "تسجيل الدخول",
    Logout: "تسجيل الخروج",
    LoginFailed: "فشل تسجيل الدخول",
    Currency: "العملة",
    Add: "اضافة",
};

export default Language;

export const ErrorTitles = {
    NetwerkError: "خطأ في الشبكة",
    Error: "خطأ",
    404: "الصفحة غير موجودة",
    401: "غير مصرح لك",
    403: "غير مصرح لك",
    400: "خطأ في الطلب",
    405: "الطريقة غير مسموح بها",
    406: "غير مقبول",
    407: "مطلوب توثيق البروكسي",
    408: "انتهت مهلة الطلب",
    409: "تعارض",
    410: "منتهي",
    411: "طول مطلوب",
    412: "فشل الشرط",
    413: "طلب كبير جدا",
    414: "طلب طويل جدا",
    415: "نوع غير مدعوم",
    416: "نطاق غير متوفر",
    417: "فشل التوقع",
    418: "انا شاي",
    419: "انتهت مهلة الجلسة",
    420: "تعذر تحديد العملية",
    421: "الطلب مقفل",
    422: "غير قابل للمعالجة",
    423: "مقفل",
    424: "فشل التبعية",
    425: "مطلوب ترتيب",
    426: "مطلوب ترقية",
    428: "مطلوب شرط",
    429: "الطلبات كثيرة جدا",
    431: "حقول الطلب كثيرة جدا",
    440: "تم تحديث الجلسة",
    444: "لا توجد رد",
    449: "اعادة توجيه",
    450: "محجوب بسبب القانون الأمريكي",
    451: "غير متاح بسبب القانون الأمريكي",
    494: "طلب ناقص",
    495: "توثيق SSL مطلوب",
    496: "توثيق SSL خاطئ",
    497: "طلب منتهي",
    499: "تم إغلاق الطلب",
    500: "خطأ داخلي في الخادم",
    501: "لم يتم تنفيذ",
    502: "بوابة خاطئة",
    503: "خدمة غير متوفرة",
    504: "انتهت مهلة البوابة",
    505: "نسخة HTTP غير مدعومة",
    506: "متعدد الخوادم",
    507: "المساحة غير كافية",
    508: "حلقة التوجيه",
    509: "تجاوز حد النقل",
    510: "لم يتم توسيع",
    511: "مطلوب توثيق الشبكة",
    520: "خطأ غير معروف",
    521: "الخادم غير متوفر",
    522: "انتهت مهلة الاتصال",
    523: "المسار غير متوفر",
    524: "انتهت مهلة الطلب",
    598: "انتهت مهلة الشبكة",
    599: "انتهت مهلة الخادم",
    600: "خطأ غير معروف",
};
