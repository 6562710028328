import React from "react";
import { Spliter } from "../Lib";

const SpliterTest = () => {
    return (
        <div className="h-screen p-md">
            <div className="overflow-auto bg-throne rounded-xl ">
                <p className="text-center text-xl text-orange py-xl">src / Examples / SpliterTest.js</p>
                <Test text={"One"} />
            </div>
            <Spliter vertical storageKey="split-store-key-one" minAfter={0.1} minBefor={0.1} />
            <div>
                <div className="bg-cyan rounded-xl ">
                    <Test text={"Two"} />
                </div>
                <Spliter storageKey="split-store-key-two" minAfter={0.1} minBefor={0.1} />
                <div className=" bg-red  rounded-xl ">
                    <Test text={"Three"} />
                </div>
                <Spliter storageKey="split-store-key-two" minAfter={0.1} minBefor={0.1} />
                <div className="text-center bg-green rounded-xl">
                    <Test text={"Four"} />
                </div>
            </div>
        </div>
    );
};

export default SpliterTest;

const Test = ({ text }) =>
    Array.from({ length: 1 }).map((_, i) => (
        <p key={i} className="text-center ">
            {text}
        </p>
    ));
