import React from "react";
import Button from "../Components/Button";
import { PageStateKit, ServiceStateBuilder } from "../Lib";

const States = Object.keys(PageStateKit);

const ServiceStateBuilderExample = () => {
    const service = { state: "none", setState: () => {} };

    return (
        <div className="col gap-md p-md relative h-fill">
            <p className="text-center text-base text-orange">src / Examples / ServiceStateBuilderExample.js</p>
            <p className="text-center text-crow text-2xl">Service State Builder Example </p>
            <div className="row-center gap-xl p-xl m-auto bg-king rounded-lg shadow-md">
                {States.map((state) => (
                    <Button
                        style={{ minWidth: "90px" }}
                        key={state}
                        title={state}
                        className="bg-prim shadow-lg"
                        onClick={() => {
                            service.setState(state);
                        }}
                    />
                ))}
                <p className="text-orange">{": SetServiceState  "}</p>
            </div>

            <ServiceStateBuilder service={service} />
        </div>
    );
};

export default ServiceStateBuilderExample;
