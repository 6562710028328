import React from "react";
import { Link } from "react-router-dom";
import { _AuthedRoutes } from "../Routes/AuthedRoutes";

const Dashboard = () => {
    return (
        <div className="h-screen col-center">
            <div className="row-center gap-lg justify-center item-center m-auto wrap">
                {_AuthedRoutes
                    .slice(1)
                    .filter((o) => !o.path.startsWith("/example/"))
                    .map(({ path, title }) => (
                        <Link to={path} key={path}>
                            <p className="text-prim font-bold bg-crow p-xl rounded-xl text-center" style={{ minWidth: 100 }}>
                                {title}
                            </p>
                        </Link>
                    ))}
            </div>
        </div>
    );
};

export default Dashboard;
