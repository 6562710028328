import React from "react";
import { JsonToView } from "../../NodeBuilder";
import { Div } from "../../NodeBuilder/NodeBuilder";

// service.setItem = React.useMemo(() => (item) => service.setItems((items) => items.map((i) => (i.id === item.id ? item : i))), []);
let viewd = 0;
let hidden = 0;
const GridNode = ({ service, ItemBuilder = ({ item }) => JsonToView({ json: item }), className = "grid", onClick }) => {
    const createMockItem = ({ item }) => {
        const { offsetHeight, offsetWidth } = service.gridNode.firstChild;
        const node = Div({ className: "bg-prim", style: `width:${offsetWidth}px;height:${offsetHeight}px;` });
        // node.setAttribute("item", JSON.stringify(item));
        // mutationObserver.observe(node, { attributes: true, childList: true, subtree: true });
        // observe.observe(node);
        // console.log("createMockItem");
        setTimeout(() => {
            // observe_obs.observe(node);
            // observe.observe(node);
        }, 10);

        return node;
    };

    const createNode = ({ item }) => {
        const node = ItemBuilder({ item, setItem: service.setItem });
        // node.setAttribute("item", JSON.stringify(item));
        // mutationObserver.observe(node, { attributes: true, childList: true, subtree: true });
        console.log("create Item");
        // setTimeout(() => {
        //     observe.observe(node);
        // }, 10);
        return node;
    };

    service.setItem = React.useMemo(
        () => (item) => {
            const node = createNode({ item });
            const foundedItem = service.gridNode.querySelector(`[id="${item.id}"]`);
            if (foundedItem) foundedItem.replaceWith(node);
            else service.gridNode.append(node);
        },
        []
    );

    const mutationObserver = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
            if (mutation.attributeName === "item") {
                console.log(mutation);
                const item = JSON.parse(mutation.target.getAttribute("item"));
                console.log(item);
                const newItem = createNode({ item });
                mutation.target.replaceWith(newItem);
            }
        });
    });

    const observe = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                viewd++;
                // console.log("viewd : ", viewd);
                // entry.target.replaceWith(createNode({ item: JSON.parse(entry.target.getAttribute("item")) }));
            } else {
                hidden++;
                // console.log("hidden : ", hidden);
                entry.target.replaceWith(createMockItem({ item: JSON.parse(entry.target.getAttribute("item")) }));
            }
        });
    });
    const observe_obs = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                console.log("viewd : ");
                entry.target.replaceWith(createNode({ item: JSON.parse(entry.target.getAttribute("item")) }));
            } else {
                // entry.target.replaceWith(createMockItem({ item: JSON.parse(entry.target.getAttribute("item")) }));
            }
        });
    });

    service.setItems = React.useMemo(() => {
        return (callBack) => {
            // console.log(typeof callBack, callBack);
            const _items = typeof callBack === "function" ? callBack(service.items) : callBack;
            _items.map((item, i) => {
                // const node = i < 25 ? createNode({ item }) : createMockItem({ item });
                const node = createNode({ item });
                service.gridNode.append(node);
            });
        };
    }, []);

    return (
        <div
            ref={(grid) => {
                if (!grid) return;
                {
                    service.gridNode = grid;
                    const _items = service.items;
                    let _i = 0;
                    for (let k = 0; k < 50; k++) {
                        _items.map((item, i) => {
                            item.id = Math.random();
                            _i++;
                            const node = createNode({ item });
                            // const node = createNode({ item });
                            service.gridNode.append(node);
                        });
                    }
                    console.log(service.gridNode.childElementCount);

                    // service.items.map((item, i) => {
                    //     const node = createNode({ item });
                    //     service.gridNode.append(node);
                    // });

                    // service.setItems(service.items);
                }
            }}
            id="grid-container"
            className={className}
            onClick={onClick}></div>
    );
};
export default React.memo(GridNode);

// interface GridProps {
//     service: any;
//     ItemBuilder?: any;
//     className?: string;
//     onClick?: any;
// }

// const Grid: React.FC<GridProps> = ({ service, ItemBuilder = JsonBuilder, className = "grid", onClick }) => {
//     [service.items, service.setItems] = React.useState(service.items);
//     service.setItem = React.useMemo(() => (item?:any) => service.setItems((items?:any) => items.map((i:any) => (i.id === item.id ? item : i))), []);
//     return (
//         <div id="grid-container" className={className} onClick={onClick}>
//             {service.items.map((item: any, i: number) => (
//                 <ItemBuilder key={i} item={item} />
//             ))}
//         </div>
//     );
// };
