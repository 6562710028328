import React from "react";
import { FormBuilder, JsonToView, Spliter } from "../../Lib";
import Button from "../../Components/Button";
import TestService from "./TestService";
import Toast from "../../Lib/Utils/Toast";

const TestServiceView = () => {
    const service = React.useMemo(() => new TestService(), []);

    const [data, setData] = React.useState();

    const getOne = async () => {
        const response = await service.getItem("1");
        setData(response);
    };

    const getAll = async () => {
        const response = await service.getItems();
        setData(response);
    };

    const post = async () => {
        setTimeout(() => {
            Toast.success({ title: "تم", content: "تمت اضافة المادة بنجاح" });
        }, 1000);
        const response = await service.addItem("posts", { title: "test", body: "test", userId: 1 });
        setData(response);
    };

    return (
        <>
            <div className="px-md">
                <p className="text-center text-base text-orange">src / Examples / ServiceExample / TestServiceView.js</p>
                <p className="text-center text-crow text-2xl">Service Example </p>

                <FormBuilder
                    fields={formFields}
                    init={(fieldsValues) => {
                        service.setQueryParmas(fieldsValues);
                    }}
                    onChange={async (field) => {
                        service.updageQueryParams(field);
                        const items = await service.getItems();
                        setData(items);
                    }}
                />

                {/* <JsonToView json={service} /> */}
                <div className="row-center gap-md p-md bg-king rounded-md bg-prim text-prim">
                    <Button title="Get All" onClick={getAll} />
                    <Button title="Get One" onClick={getOne} />
                    <Button title="Post" onClick={post} />
                </div>
            </div>
            <Spliter vertical storageKey="service-example" initialRatios={[0.1, 0.9]} />
            <div className="p-md mx-md overflow-auto scroller bg-throne rounded-xl">
                {data && <JsonToView className={data.length > 1 ? "grid" : "json-builder"} json={data} />}
            </div>
        </>
    );
};

export default TestServiceView;
const formFields = [
    {
        className: "row-center rounded-md gap-md bg-throne py-xs px-md self-start items-cen",
        children: [
            {
                id: "title",
                type: "search",
                title: "Title",
                value: "",
                // storageKey: "q-title",
                containerClass: "query-input",
            },
            {
                id: "userId",
                type: "selector",
                title: "userId",
                value: "",
                // storageKey: "q-userId",
                options: [
                    { id: "", title: "all users" },
                    { id: "1", title: "one" },
                    { id: "2", title: "two" },
                    { id: "3", title: "three" },
                ],
            },
            {
                id: "id",
                type: "popupSelector",
                title: "all ids",
                value: "",
                // storageKey: "q-id",
                options: [
                    { id: "", title: "all ids" },
                    { id: "1", title: "one" },
                    { id: "2", title: "two" },
                    { id: "3", title: "three" },
                ],
            },
            {
                id: "deleted",
                type: "toggle",
                title: "المحذوفة",
                value: false,
                // storageKey: "q-deleted",
            },
        ],
    },
];
