import React from "react";
import JsonToView from "../Lib/NodeBuilder/JsonToView";
import Refresher from "../Lib/ServiceProvider/Refresher";

const RefresherExample = () => {
    const service = { state: "none" };
    const onRefresh = () => alert("refreshed");

    const RandomJsonExample = {
        testKey: "testValue",
        lorem: "   lremp ipsum dolor sit amet consectetur adipisicing elit. Quisquam, quod voluptate. Quisquam, quod voluptate. Quisquam, quod voluptate.Quisquam, quod voluptate. Quisquam, quod voluptate. Quisquam, quod voluptate. Quisquam, quod voluptate. Quisquam, quod voluptate. Quisquam,quod",
    };

    return (
        <Refresher className="m-md col gap-xl my-3xl h-fill" service={service} onRefresh={onRefresh}>
            <p className="text-center text-base text-orange">src / Examples / RefresherExample.js</p>
            <p className="text-center text-crow text-2xl">Refresher Example </p>
            <p className="text-center text-crow text-lg bg-dolphin">Work with touch only</p>
            <JsonToView
                className="self-center"
                json={{
                    pull: "to refresh",
                }}
            />
            <JsonToView json={RandomJsonExample} />
        </Refresher>
    );
};

export default RefresherExample;
