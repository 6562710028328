import "../Kit/formkit.css";
import React from "react";
import FormGroupBuilder from "./FormGroupBuilder";
import FormSecripts from "./FormScripts";

const FormBuilder = ({ service, fields, onChange = service?.updateQueryParams, init = service?.initQueryParams, className = "form-container" }) =>
    React.useMemo(() => {
        if (init) init(FormSecripts.extractValues(fields));

        return (
            <div className={className}>
                <FormGroupBuilder prop={{ children: fields, onChange }} />
                {/* <FormCleaner service={service} /> */}
            </div>
        );
    }, []);

export default FormBuilder;
