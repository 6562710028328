import "./index.css";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import FirstSetup from "./Lib/Utils/FirstSetup";

let token = localStorage.getItem("token");
if (!token) {
    token = "VZNjk7Iu9WiuwEP0UBXQpBK33wo5YgAIDAWWMUgkt8MH0psj";
    localStorage.setItem("token", token); // naruto
}

FirstSetup();

const link = document.createElement("a");
// Create a blog object with the file content which you want to add to the file
const file = new Blob(["lol", "LOLS"], { type: "text/plain;charset=utf-8" });
// Add file content in the object URL
link.href = URL.createObjectURL(file);
// Add file name
link.download = "sample.js";
// link.click();
URL.revokeObjectURL(link.href);

createRoot(document.getElementById("root")).render(
    <Router>
        <App token={token} />
    </Router>
);
