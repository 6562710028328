import React from "react";
import JsonParser from "../../NodeBuilder/JsonParser.js";
import { Div, P } from "../../NodeBuilder/NodeBuilder.js";
import Utils from "../../Utils/Utils.js";

export default class RecyclerList extends React.Component {
    constructor({ itemBuilder, service }) {
        super();

        const observe = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add("intersecting");
                    } else {
                        entry.target.classList.remove("intersecting");
                    }
                });
            }
            // ,{ threshold: 1, root: document.getElementById("recycler"), rootMargin: "0px" }
        );

        const useRecycler = localStorage.useRecycler !== "Disable Recycler";
        this.itemBuilder = itemBuilder;
        this.service = service;

        this.buildItem = ({ item, i }) => {
            const node = this.itemBuilder({ item, i });
            node.classList.add("intersecting");
            // observe.observe(node);
            return node;
        };

        this._lastIndex = 50;
        this.itemsCount = 50;

        this.colsCounts = 0;
        this.rowsCounts = 0;
        this.threshold = 0;
        this.scrollHeight = 0;
        this.centerOfContainer = 0;

        this.lastScrollTop = 0;

        this.reachedBottom = false;
        this.reachedTop = false;

        this.handleCalcolations = () => {
            this.colsCounts = Math.floor(this.container.offsetWidth / this.grid.firstChild.offsetWidth);
            this.rowsCounts = Math.floor(this.itemsCount / this.colsCounts) * this.colsCounts;
            this.threshold = 500; //this.container.offsetWidth > 500 ? 200 : 200;
            this.scrollHeight = this.container.scrollHeight - this.container.offsetHeight;
            this.centerOfContainer = this.scrollHeight / 2;
            this._lastIndex = this.rowsCounts;
        };

        this.grid = Div(
            // { className: "col gap-lg", style: "padding-block:400px" },
            { className: "col gap-lg" },
            service.items.slice(0, this._lastIndex).map((item, i) => this.buildItem({ item, i }))
        );
    }

    componentDidMount() {
        this.container = document.getElementById("recycler");
        this.container.append(this.grid);

        this.handleCalcolations();
        this.grid.append(...this.service.items.slice(10, this._lastIndex).map((item, i) => this.buildItem({ item, i })));
        this.handleCalcolations();

        this.scrollerIndecator = createIndecator(this);
        this.container.parentElement.append(this.scrollerIndecator);

        this.container.addEventListener("scroll", ({ target }) => {
            recyclingOnScroll(target, this);
        });
        window.addEventListener("resize", this.handleCalcolations);
    }

    render() {
        return (
            <div className="relative m-auto" style={{ maxWidth: 400, width: "100%" }}>
                <div id="recycler" className="wrapper relative hide-scroller py-5xl transition-3 " style={{ maxWidth: "100%", maxHeight: "100%" }}></div>;
            </div>
        );
    }
}

const recyclingOnScroll = (target, recycler) => {
    const scrollDown = target.scrollTop >= recycler.lastScrollTop;
    recycler.lastScrollTop = target.scrollTop;

    recycler.updateIndecator();

    // console.log(scrollDown ? "down" : "up");
    if (target.scrollTop > recycler.centerOfContainer + recycler.threshold) {
        if (scrollDown) {
            recycler.reachedBottom = target.scrollTop >= recycler.scrollHeight;
            // if (recycler.reachedBottom) {
            //     if (recycler._lastIndex + recycler.colsCounts >= recycler.service.items.length) {
            //         if (!recycler.service.canFetch) return;
            //         recycler.service.canFetch = false;
            //         recycler.service.loadMore().then(() => {
            //             setTimeout(() => {
            //                 onScrollDown(recycler);
            //             }, 100);
            //         });
            //         return;
            //     }
            // }
            onScrollDown(recycler);
        }
    } else if (target.scrollTop < recycler.centerOfContainer - recycler.threshold) {
        if (!scrollDown) {
            recycler.reachedTop = recycler._lastIndex <= recycler.rowsCounts;
            if (recycler.reachedTop) return;
            if (target.scrollTop < 1) setTimeout(() => (target.scrollTop = 1), 10);
            onScrollUp(recycler);
        }
    } else {
        // console.log("center");
    }
};

const onScrollDown = (recycler) => {
    let start = recycler._lastIndex;
    recycler._lastIndex += recycler.colsCounts;
    let array = recycler.service.items.slice(start, recycler._lastIndex);
    for (let i = 0; i < array.length; i++) {
        let itemNode = recycler.buildItem({ item: array[i], setItem: recycler.service.setItem });
        recycler.grid.firstChild.remove();
        recycler.grid.append(itemNode);
    }
};

const onScrollUp = (recycler) => {
    recycler._lastIndex -= recycler.colsCounts;
    let end = recycler._lastIndex - recycler.rowsCounts;
    let start = end + recycler.colsCounts;
    let array = recycler.service.items.slice(end, start);
    for (let i = array.length - 1; i > -1; i--) {
        let itemNode = recycler.buildItem({ item: array[i], setItem: recycler.service.setItem });
        recycler.grid.lastChild.remove();
        recycler.grid.prepend(itemNode);
    }
};

const createIndecator = (recycler = new RecyclerList()) => {
    const scrollerIndecator = P({ className: "scroller-indecator" });
    scrollerIndecator.style.height = `${((40 || recycler._lastIndex) / recycler.service.items.length) * 1000}px`;
    recycler.updateIndecator = () => {
        scrollerIndecator.style.top = `${
            ((recycler._lastIndex - recycler.rowsCounts + recycler.colsCounts) / recycler.service.items.length) * recycler.container.offsetHeight
        }px`;
    };
    recycler.onSwipeIndecator = (e) => {
        const scrollDown = e.clientY > recycler.lastPointerY;
        recycler.lastPointerY = e.clientY;
        const lastIndex = (e.clientY / recycler.container.offsetHeight) * recycler.service.items.length;
        if (lastIndex < recycler.itemsCount || lastIndex > recycler.service.items.length) return;

        // console.log(lastIndex);
        recycler._lastIndex = lastIndex - (lastIndex % recycler.colsCounts);

        if (scrollDown) {
            onScrollDown(recycler);
            recycler.container.scrollTop = recycler.scrollHeight;
        } else {
            onScrollUp(recycler);
            recycler.container.scrollTop = 0;
        }
        recycler.updateIndecator();
    };

    scrollerIndecator.addEventListener("mousedown", (e) => {
        recycler.lastPointerY = e.clientY;
        window.addEventListener("mousemove", recycler.onSwipeIndecator);
        window.addEventListener("mouseup", () => {
            console.log(recycler._lastIndex);
            recycler.grid.replaceChildren(
                ...recycler.service.items
                    .slice(recycler._lastIndex - recycler.rowsCounts, recycler._lastIndex)
                    .map((item, i) => recycler.buildItem({ item, i }))
            );
            recycler.container.scrollTop = recycler.container.scrollTop > recycler.centerOfContainer ? recycler.container.scrollHeight : 0;
            window.removeEventListener("mousemove", recycler.onSwipeIndecator);
        });
    });

    return scrollerIndecator;
};
