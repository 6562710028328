import React from "react";
import JsonParser from "../../NodeBuilder/JsonParser.js";
import { Div, P } from "../../NodeBuilder/NodeBuilder.js";
import Utils from "../../Utils/Utils.js";
let observe = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            entry.target.classList.add("intersecting");
        } else {
            entry.target.classList.remove("intersecting");
        }
    });
});

export async function RecyclerScroller({ container, itemBuilder: ItemBuilder, service }) {
    observe = new IntersectionObserver(
        (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    entry.target.classList.add("intersecting");
                } else {
                    entry.target.classList.remove("intersecting");
                }
            });
        },
        {
            root: container,
            rootMargin: "0px",
            threshold: 1,
        }
    );

    const useRecycler = localStorage.useRecycler !== "Disable Recycler";
    const scrollerIndecator = P({ className: "scroller-indecator" });
    const itemBuilder = ({ item, i }) => {
        const itemNode = ItemBuilder({ item, i });
        itemNode.out = () => {
            // itemNode.classList.remove("intersecting");
            itemNode.remove();

            return;
            const top = itemNode.offsetTop;
            const left = itemNode.offsetLeft;
            // itemNode.parentNode.removeChild(itemNode);

            itemNode.style.top = top + "px";
            itemNode.style.left = left + "px";
            itemNode.style.width = itemNode.offsetWidth + "px";
            itemNode.style.height = itemNode.offsetHeight + "px";
            itemNode.style.position = "fixed";
            itemNode.style.zIndex = 10000;

            // document.body.append(itemNode);
            setTimeout(() => {
                itemNode.remove();
            }, 500);
        };
        observe.observe(itemNode);

        return itemNode;
    };

    const itemsCount = 5;
    const grid = Div(
        { className: "col gap-md", style: "padding-block: 350px;" },
        service.items.slice(0, itemsCount).map((item, i) => {
            const itemNode = itemBuilder({ item, i });
            return itemNode;
        })
    );
    container.append(grid);

    await Utils.sleep(1);

    let colsCounts = Math.floor(container.offsetWidth / grid.firstChild.offsetWidth);
    let rowsCounts = Math.floor(itemsCount / colsCounts) * colsCounts;
    const threshold = 200; //container.offsetWidth > 500 ? 200 : 200;
    let scrollHeight = container.scrollHeight - (container.offsetHeight + 700);
    let centerOfContainer = scrollHeight / 2;

    console.log({ threshold, centerOfContainer, scrollHeight: container.scrollHeight, offsetHeight: container.offsetHeight });

    const onResiaze = () => {
        rowsCounts = Math.floor(itemsCount / colsCounts) * colsCounts;
        colsCounts = Math.floor(container.offsetWidth / grid.firstChild.offsetWidth);
        console.log({ colsCounts, rowsCounts });
        scrollHeight = container.scrollHeight - container.offsetHeight;
        centerOfContainer = scrollHeight / 2;
    };
    console.log({ colsCounts, rowsCounts });
    window.addEventListener("resize", onResiaze);

    let _lastIndex = useRecycler ? rowsCounts : service.items.length;

    grid.append(
        ...service.items.slice(10, _lastIndex).map((item, i) => {
            const itemNode = itemBuilder({ item, i });
            return itemNode;
        })
    );
    container.append(scrollerIndecator);

    console.log(service);

    // const container = Div({ className: "wrapper relative hide-scroller py-5xl ", id: "owl-scroller" }, []);
    let lastScrollTop = 0;
    let richedEnd = 0;

    let prossing = false;
    // const infos = Div({ className: "absolute top-0 z-50" }, [JsonParser({ _firstIndex, _lastIndex, scrollTop: 0, lastScrollTop })]);

    let firstY = 0;
    let delay = false;
    const onSwipeIndecator = ({ clientY }) => {
        //if (delay) return;delay = true;setTimeout(() => {delay = false;}, 10);
        const scrollDown = clientY > firstY;
        firstY = clientY;
        const target = container;
        // infos.replaceChildren(JsonParser({ itemsLength: Utils.Formate(service.items.length), viewedItems: Utils.Formate(_lastIndex), lastScrollTop, rowsCounts }));
        // if (scrollDown) {
        //     target.scrollTop += threshold;
        // } else {
        //     target.scrollTop -= threshold;
        // }
        if (scrollDown) {
            const richedEnd = target.scrollTop > target.scrollHeight - threshold;
            if (richedEnd) return;
            _lastIndex = onScrollDown({ _lastIndex, colsCounts, itemBuilder, service, grid });
            // if (target.scrollTop > target.scrollHeight - 1) target.scrollTop = target.scrollHeight - 1;
        } else {
            const richedTop = _lastIndex <= rowsCounts;
            if (richedTop) return;
            _lastIndex = onScrollUp({ _lastIndex, colsCounts, itemBuilder, service, grid, rowsCounts });
            // if (target.scrollTop < 1) target.scrollTop = 1;
        }
        scrollerIndecator.style.top = `${((_lastIndex - rowsCounts + colsCounts) / service.items.length) * target.offsetHeight}px`;
    };

    scrollerIndecator.addEventListener("mousedown", (e) => {
        firstY = e.clientY;
        window.addEventListener("mousemove", onSwipeIndecator);
    });
    const touchMove = (e) => {
        e.preventDefault();
        e.stopPropagation();
        onSwipeIndecator(e.touches[0]);
    };
    scrollerIndecator.addEventListener("touchstart", (e) => {
        e.preventDefault();
        e.stopPropagation();
        firstY = e.touches[0].clientY;
        window.addEventListener("touchmove", touchMove);
    });

    window.addEventListener("mouseup", (e) => {
        window.removeEventListener("mousemove", onSwipeIndecator);
        window.removeEventListener("touchmove", touchMove);
    });

    const infos = Div({ className: "fixed right-0 top-0 z-50 col bg-prim p-sm rounded-lg" }, [
        P({
            className: "text-sm button self-center",
            innerText: useRecycler ? "Disable Recycler" : "Enable Recycler",
            onclick: () => {
                localStorage.useRecycler = useRecycler ? "Disable Recycler" : "Enable Recycler";
                window.location.reload();
            },
        }),
        JsonParser({ itemsLength: Utils.Formate(service.items.length), viewedItems: Utils.Formate(_lastIndex) }),
    ]);
    container.append(infos);
    if (!useRecycler) return container;

    scrollerIndecator.style.height = `${(_lastIndex / service.items.length) * 1000}px`;
    setTimeout(() => {
        scrollerIndecator.style.top = `${((_lastIndex - rowsCounts + colsCounts) / service.items.length) * container.offsetHeight}px`;
    }, 10);

    await Utils.sleep(10);
    scrollHeight = container.scrollHeight - container.offsetHeight;
    centerOfContainer = scrollHeight / 2;
    console.log({ threshold, centerOfContainer, scrollHeight: container.scrollHeight, offsetHeight: container.offsetHeight });

    container.addEventListener("scroll", (e) => {
        // if (prossing) return; prossing = true; setTimeout(() => { prossing = false; }, 3);

        const target = e.target;
        // _ScrollTop = lastScrollTop - target.scrollTop;
        const scrollDown = target.scrollTop >= lastScrollTop;
        lastScrollTop = target.scrollTop;

        scrollerIndecator.style.top = `${((_lastIndex - rowsCounts + colsCounts) / service.items.length) * target.offsetHeight}px`;

        // infos.replaceChildren(
        //     JsonParser({
        //         //
        //         rowsCounts,
        //         itemsLength: Utils.Formate(service.items.length),
        //         viewedItems: Utils.Formate(_lastIndex),
        //         indecator: (_lastIndex / service.items.length) * target.offsetHeight,
        //         lastScrollTop,
        //         offsetHeight: target.offsetHeight,
        //         scrollHeight: target.scrollHeight,
        //     })
        // );
        // console.log(Math.round(target.scrollTop), centerOfContainer, scrollHeight);
        if (target.scrollTop > centerOfContainer + threshold) {
            if (scrollDown) {
                // if (target.scrollTop > target.scrollHeight - 1) {
                //     lastScrollTop = target.scrollHeight - 3;
                //     target.scrollTo(0, lastScrollTop);
                // }

                richedEnd = target.scrollTop > target.scrollHeight - threshold;
                console.log("scroll down", richedEnd);
                if (richedEnd) {
                    if (_lastIndex + colsCounts >= service.items.length) {
                        if (prossing || !service.canFetch) return;
                        service.canFetch = false;
                        service.loadMore().then(() => {
                            setTimeout(() => {
                                _lastIndex = onScrollDown({ _lastIndex, colsCounts, itemBuilder, service, grid });
                            }, 100);
                        });
                        return;
                    }
                }
                _lastIndex = onScrollDown({ _lastIndex, colsCounts, itemBuilder, service, grid });
            }
        } else if (target.scrollTop < centerOfContainer - threshold) {
            const richedTop = _lastIndex <= rowsCounts;
            console.log("scrollUp", richedTop);
            if (richedTop) return;
            if (target.scrollTop < 1) target.scrollTop = 1;

            _lastIndex = onScrollUp({ _lastIndex, colsCounts, itemBuilder, service, grid, rowsCounts });
        } else {
            // console.log("Middle");
        }

        // infos.replaceChildren(JsonParser({ _firstIndex, _lastIndex, scrollTop: target.scrollTop, lastScrollTop }));
    });
}
let init = false;
const RecyclerGrid = ({ ItemBuilder, service }) => {
    return (
        <div
            ref={(ref) => {
                if (init || !ref) return;
                init = true;
                Utils.sleep(100).then(() => {
                    RecyclerScroller({ container: ref, itemBuilder: ItemBuilder, service });
                });
            }}
            className="wrapper relative hide-scroller py-5xl transition-3"
            style={{
                maxWidth: "400px",
                margin: "auto",
            }}></div>
    );
};

const onScrollDown = ({ _lastIndex, colsCounts, itemBuilder, service, grid }) => {
    let start = _lastIndex;
    _lastIndex += colsCounts;
    let array = service.items.slice(start, _lastIndex);

    for (let i = 0; i < array.length; i++) {
        let itemNode = itemBuilder({ item: array[i], setItem: service.setItem });
        grid.firstChild.out();
        grid.append(itemNode);
    }
    return _lastIndex;
};

const onScrollUp = ({ _lastIndex, colsCounts, itemBuilder, service, grid, rowsCounts }) => {
    _lastIndex -= colsCounts;
    let end = _lastIndex - rowsCounts;
    let start = end + colsCounts;
    let array = service.items.slice(end, start);
    for (let i = array.length - 1; i > -1; i--) {
        let itemNode = itemBuilder({ item: array[i], setItem: service.setItem });
        grid.lastChild.out();
        grid.prepend(itemNode);
    }
    return _lastIndex;
};

export default React.memo(RecyclerGrid);
